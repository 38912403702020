import { CommonModule, isPlatformBrowser } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit, PLATFORM_ID, Renderer2, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import Swal from 'sweetalert2';
import { Usuario } from '../../Models/usuario';
import { ApiProductosService } from '../../services/api-productos.service';
import { CheckoutServiceService } from '../../services/checkout-service.service';
import { LoginService } from '../../services/login.service';
import { RegistroService } from '../../services/registro.service';

@Component({
  standalone: true,
  imports: [RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule],
  selector: 'app-administra-direcciones',
  templateUrl: './administra-direcciones.component.html',
  styleUrls: ['./administra-direcciones.component.scss'],
})
export class AdministraDireccionesComponent implements OnInit {

  cargando = false;
  usuario!: Usuario;
  direccion: any = [];
  nombreEstado: any;
  email: any;
  id_address: any;
  estoycargando = false;


  @ViewChild('modal_wp') modal_wp!: ElementRef<HTMLInputElement>;
  @ViewChild('modal_wp2') modal_wp2!: ElementRef<HTMLInputElement>;
  modal_wpIs = false;
  modal_wpIs2 = false;
  direccionesForm!: UntypedFormGroup;
  public datosDireccion: any = []
  public ciudadsinCodigo: any;
  public nombreColonia: any;
  public ciudadSeleccionada: any;
  public codigoEscrito = 0;
  public countyId: any;
  public codiguillo: any = [];
  public arrayCiudades: any = [];
  public arrayColonias: any = []
  public arraicitoCiudades: any = [];
  public arraisitoColonias: any = [];
  public arraicitoEstado: any = [];
  public ciudadilla: any;
  public accion: any;

  constructor(private cdr: ChangeDetectorRef,
    private renderer2: Renderer2,
    public registroService: RegistroService,
    private loginService: LoginService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private checkoutService: CheckoutServiceService,
    private productoService: ApiProductosService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object) {

    this.direccionesForm = this.formBuilder.group({
      zipcode: ['', Validators.required],
      stateValue: ['', Validators.required],
      cityValue: ['', Validators.required],
      colonyValue: ['', Validators.required],
      countryValue: [''],
      alias: [''],
      address1: ['', Validators.required],
      address2: [''],
      phonenumber: ['', [
        Validators.required,
        Validators.pattern(/^\d{10}$/)
      ]]
    });

  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {

      this.loginService.usuario.subscribe(res => {
        if (!res) return
        this.usuario = res;
        //console.log(this.usuario);
      });

      this.tambienChecalo();

    }

  }


  handleWP() {
    // this.direccionesForm.controls['zipcode'].setValue('');
    // this.direccionesForm.controls['stateValue'].setValue('');
    // this.direccionesForm.controls['cityValue'].setValue('');
    // this.direccionesForm.controls['stateValue'].setValue(this.nombreEstado);
    // this.direccionesForm.controls['colonyValue'].setValue('');
    // this.direccionesForm.controls['countryValue'].setValue('');
    // this.direccionesForm.controls['alias'].setValue('');
    // this.direccionesForm.controls['address1'].setValue('');
    // this.direccionesForm.controls['address2'].setValue('');
    // this.direccionesForm.controls['phonenumber'].setValue('');
    // this.ciudadsinCodigo = this.nombreEstado;
    // this.ciudadSeleccionada = '';
    // this.nombreColonia = '';
    if (this.modal_wpIs == false) {
      this.renderer2.setStyle(this.modal_wp.nativeElement, 'display', 'flex');

      this.modal_wpIs = true
    } else if (this.modal_wpIs == true) {
      this.renderer2.setStyle(this.modal_wp.nativeElement, 'display', 'none');

      this.modal_wpIs = false
    }
  }

  handleWP2() {
    this.direccionesForm.controls['zipcode'].setValue('');
    this.direccionesForm.controls['stateValue'].setValue('');
    this.direccionesForm.controls['cityValue'].setValue('');
    // this.direccionesForm.controls['stateValue'].setValue(this.nombreEstado);
    this.direccionesForm.controls['colonyValue'].setValue('');
    this.direccionesForm.controls['countryValue'].setValue('');
    this.direccionesForm.controls['alias'].setValue('');
    this.direccionesForm.controls['address1'].setValue('');
    this.direccionesForm.controls['address2'].setValue('');
    this.direccionesForm.controls['phonenumber'].setValue('');
    this.ciudadsinCodigo = this.nombreEstado;
    this.ciudadSeleccionada = '';
    this.nombreColonia = '';
    if (this.modal_wpIs2 == false) {
      this.renderer2.setStyle(this.modal_wp2.nativeElement, 'display', 'flex');

      this.modal_wpIs2 = true
    } else if (this.modal_wpIs2 == true) {
      this.renderer2.setStyle(this.modal_wp2.nativeElement, 'display', 'none');

      this.modal_wpIs2 = false
    }
  }

  tambienChecalo() {
    this.cargando = true;
    this.checkoutService.getDireccion(this.usuario.usuario).subscribe(res => {
      try {
        this.direccion = res;
        for (let i = 0; i < this.direccion.length; i++) {
          const element = this.direccion[i].id_state;
          this.direccion[i].nombreEstado = this.getNombreEstado(element);
        }

        if (this.usuario) {
          this.email = this.usuario.usuario;
        } else {
          this.email = "";
        }

        let datos = {
          "email": this.email
        };

        this.cargando = false;
      } catch (error) {
        this.cargando = false;
      }
    });
  }

  borrame(id_address: any) {
    Swal.fire({
      title: '¿Estas seguro?',
      text: "La Dirección sera borrada",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Borrar'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Borrada!',
          'La Dirección ha sido borrada.',
          'success'
        )
        this.loginService.BorrarDatosDir(id_address).subscribe(res => {
          // location.reload();
          window.location.reload();
        });
      }

    })
  }

  traeDatosDireccion(id_address: any) {
    this.id_address = id_address;

    this.loginService.recuperaDatosDireccion(this.id_address).subscribe(datosDireccion => {
      this.datosDireccion = datosDireccion;

      this.direccionesForm.controls['zipcode'].setValue(this.datosDireccion[0].postcode);
      this.nombreEstado = this.getNombreEstado(this.datosDireccion[0].id_state);
      console.log(this.nombreEstado);
      // this.direccionesForm.controls['stateValue'].setValue(this.nombreEstado);
      this.ciudadsinCodigo = this.nombreEstado;
      // this.direccionesForm.controls['cityValue'].setValue(this.datosDireccion[0].city);
      // this.direccionesForm.controls['stateValue'].setValue(this.nombreEstado);
      // this.direccionesForm.controls['colonyValue'].setValue(this.datosDireccion[0].colony);
      this.direccionesForm.controls['countryValue'].setValue(this.datosDireccion[0].id_country);
      this.direccionesForm.controls['alias'].setValue(this.datosDireccion[0].alias);
      this.direccionesForm.controls['address1'].setValue(this.datosDireccion[0].address1);
      this.direccionesForm.controls['address2'].setValue(this.datosDireccion[0].address2);
      this.direccionesForm.controls['phonenumber'].setValue(this.datosDireccion[0].phone);
      // this.ciudadsinCodigo = this.nombreEstado;
      this.ciudadSeleccionada = this.datosDireccion[0].city;
      this.nombreColonia = this.datosDireccion[0].colony;

      // Verificar si el estado está vacío
      if (!this.datosDireccion[0].id_state || this.datosDireccion[0].id_state === "0") {
        // Intentar obtener el estado usando el código postal
        this.buscameXCodigoPostal(this.datosDireccion[0].postcode);
      } else {
        // Si el estado está disponible, mapearlo al nombre del estado
        this.nombreEstado = this.getNombreEstado(parseInt(this.datosDireccion[0].id_state, 10));
        this.direccionesForm.controls['stateValue'].setValue(this.nombreEstado);
      }

      // Verificar si la ciudad está vacía
      if (!this.datosDireccion[0].city || this.datosDireccion[0].city.trim() === "") {
        // Intentar obtener la ciudad usando el código postal
        this.buscameXCodigoPostal(this.datosDireccion[0].postcode);
      } else {
        // Si la ciudad está disponible, asignarla al formulario
        this.ciudadSeleccionada = this.datosDireccion[0].city;
        this.direccionesForm.controls['cityValue'].setValue(this.ciudadSeleccionada);
      }

      // Verificar si la colonia está vacía
      if (!this.datosDireccion[0].colony || this.datosDireccion[0].colony.trim() === "") {
        // Intentar obtener la colonia usando el código postal
        this.buscameXCodigoPostal(this.datosDireccion[0].postcode);
      } else {
        // Si la colonia está disponible, asignarla al formulario
        this.nombreColonia = this.datosDireccion[0].colony;
        this.direccionesForm.controls['colonyValue'].setValue(this.nombreColonia);
      }

      this.handleWP();

    });
  }

  getNombreEstado(id_state: number): string {
    let nombreEstado = "";
    if (id_state == 57) {
      nombreEstado = "Aguascalientes";
    } else if (id_state == 58) {
      nombreEstado = "Baja California";
    } else if (id_state == 59) {
      nombreEstado = "Baja California Sur";
    } else if (id_state == 60) {
      nombreEstado = "Campeche";
    } else if (id_state == 61) {
      nombreEstado = "Chiapas";
    } else if (id_state == 62) {
      nombreEstado = "Chihuahua";
    } else if (id_state == 63) {
      nombreEstado = "Coahuila";
    } else if (id_state == 64) {
      nombreEstado = "Colima";
    } else if (id_state == 65) {
      nombreEstado = "Distrito Federal";
    } else if (id_state == 66) {
      nombreEstado = "Durango";
    } else if (id_state == 67) {
      nombreEstado = "Guanajuato";
    } else if (id_state == 68) {
      nombreEstado = "Guerrero";
    } else if (id_state == 69) {
      nombreEstado = "Hidalgo";
    } else if (id_state == 70) {
      nombreEstado = "Jalisco";
    } else if (id_state == 71) {
      nombreEstado = "Estado de Mexico";
    } else if (id_state == 72) {
      nombreEstado = "Michoacan";
    } else if (id_state == 73) {
      nombreEstado = "Morelos";
    } else if (id_state == 74) {
      nombreEstado = "Nayarit";
    } else if (id_state == 75) {
      nombreEstado = "Nuevo Leon";
    } else if (id_state == 76) {
      nombreEstado = "Oaxaca";
    } else if (id_state == 77) {
      nombreEstado = "Puebla";
    } else if (id_state == 78) {
      nombreEstado = "Queretaro";
    } else if (id_state == 79) {
      nombreEstado = "Quintana Roo";
    } else if (id_state == 80) {
      nombreEstado = "San Luis Potosi";
    } else if (id_state == 81) {
      nombreEstado = "Sinaloa";
    } else if (id_state == 82) {
      nombreEstado = "Sonora";
    } else if (id_state == 83) {
      nombreEstado = "Tabasco";
    } else if (id_state == 84) {
      nombreEstado = "Tamaulipas";
    } else if (id_state == 85) {
      nombreEstado = "Tlaxcala";
    } else if (id_state == 86) {
      nombreEstado = "Veracruz";
    } else if (id_state == 87) {
      nombreEstado = "Yucatan";
    } else if (id_state == 88) {
      nombreEstado = "Zacatecas";
    } else if (id_state == null || id_state == undefined) {
      nombreEstado = "";
    }
    return nombreEstado;
  }

  imprimir() {
    //console.log(this.direccionesForm.controls.colonyValue.value);
    var separame = this.direccionesForm.controls['colonyValue'].value.split('-');
    var a = separame[0];
    var b = separame[1]
    this.nombreColonia = a;
    this.countyId = b;

  }

  allowOnlyNumbers(event: KeyboardEvent) {
    const key = event.key;

    // Permitir solo números (0-9)
    if (!/[0-9]/.test(key)) {
      event.preventDefault();
    }
  }

  buscameXCodigoPostal(codigo: any) {
    try {


      this.cargando = true;
      this.nombreColonia = "--- Selecciona Colonia ---";
      this.registroService.getEstados(codigo).subscribe(codigazo => {
        this.arraicitoEstado = codigazo
        this.codiguillo = this.arraicitoEstado.data;
        if (this.codiguillo == null || this.codiguillo == "") {
          this.nohaycodigo();
          this.cargando = false;
          this.codigoEscrito = 0;
        } else {
          this.codigoEscrito = 1;
          this.ciudadilla = this.codiguillo.stateId;

          if (this.ciudadilla == 'AGS') { this.ciudadsinCodigo = "Aguascalientes" }
          else if (this.ciudadilla == 'BCA') { this.ciudadsinCodigo = "Baja California" }
          else if (this.ciudadilla == 'BCS') { this.ciudadsinCodigo = "Baja California Sur" }
          else if (this.ciudadilla == 'CAM') { this.ciudadsinCodigo = "Campeche" }
          else if (this.ciudadilla == 'CHI') { this.ciudadsinCodigo = "Chiapas" }
          else if (this.ciudadilla == 'CHH') { this.ciudadsinCodigo = "Chihuahua" }
          else if (this.ciudadilla == 'COA') { this.ciudadsinCodigo = "Coahuila" }
          else if (this.ciudadilla == 'COL') { this.ciudadsinCodigo = "Colima" }
          else if (this.ciudadilla == 'DIF') { this.ciudadsinCodigo = "Distrito Federal" }
          else if (this.ciudadilla == 'DGO') { this.ciudadsinCodigo = "Durango" }
          else if (this.ciudadilla == 'GTO') { this.ciudadsinCodigo = "Guanajuato" }
          else if (this.ciudadilla == 'GRO') { this.ciudadsinCodigo = "Guerrero" }
          else if (this.ciudadilla == 'HGO') { this.ciudadsinCodigo = "Hidalgo" }
          else if (this.ciudadilla == 'JAL') { this.ciudadsinCodigo = "Jalisco" }
          else if (this.ciudadilla == 'CDMX') { this.ciudadsinCodigo = "Ciudad de México" }
          else if (this.ciudadilla == 'MIC') { this.ciudadsinCodigo = "Michoacán" }
          else if (this.ciudadilla == 'MEX') { this.ciudadsinCodigo = "Estado de México" }
          else if (this.ciudadilla == 'MOR') { this.ciudadsinCodigo = "Morelos" }
          else if (this.ciudadilla == 'NAY') { this.ciudadsinCodigo = "Nayarit" }
          else if (this.ciudadilla == 'NLE') { this.ciudadsinCodigo = "Nuevo León" }
          else if (this.ciudadilla == 'OAX') { this.ciudadsinCodigo = "Oaxaca" }
          else if (this.ciudadilla == 'PUE') { this.ciudadsinCodigo = "Puebla" }
          else if (this.ciudadilla == 'QRO') { this.ciudadsinCodigo = "Querétaro" }
          else if (this.ciudadilla == 'ROO') { this.ciudadsinCodigo = "Quintana Roo" }
          else if (this.ciudadilla == 'SLP') { this.ciudadsinCodigo = "San Luis Potosí" }
          else if (this.ciudadilla == 'SIN') { this.ciudadsinCodigo = "Sinaloa" }
          else if (this.ciudadilla == 'SON') { this.ciudadsinCodigo = "Sonora" }
          else if (this.ciudadilla == 'TAB') { this.ciudadsinCodigo = "Tabasco" }
          else if (this.ciudadilla == 'TAM') { this.ciudadsinCodigo = "Tamaulipas" }
          else if (this.ciudadilla == 'TLA') { this.ciudadsinCodigo = "Tlaxcala" }
          else if (this.ciudadilla == 'VER') { this.ciudadsinCodigo = "Veracruz" }
          else if (this.ciudadilla == 'YUC') { this.ciudadsinCodigo = "Yucatán" }
          else if (this.ciudadilla == 'ZAC') { this.ciudadsinCodigo = "Zacatecas" }
          this.direccionesForm.controls['stateValue'].setValue(this.ciudadsinCodigo);

          this.registroService.getCiudades(codigo, this.ciudadilla).subscribe(ciudadcillas => {

            this.arraicitoCiudades = ciudadcillas
            this.arrayCiudades = this.arraicitoCiudades.data;
            this.ciudadSeleccionada = this.arraicitoCiudades.data[0].ciudadSeleccionada
            this.direccionesForm.controls['cityValue'].setValue(this.ciudadSeleccionada);


            this.registroService.getColonias(codigo).subscribe(coloniecillas => {
              this.arraisitoColonias = coloniecillas
              this.arrayColonias = this.arraisitoColonias.data;
              this.nombreColonia = this.arrayColonias.colonia;
              this.direccionesForm.controls['colonyValue'].setValue(this.nombreColonia);


              this.cargando = false;
            })
          })
        }

      })
    } catch (error) {
      this.cargando = false;
    }
  }

  guardaDireccion() {

    if (this.direccionesForm.invalid) {
      this.faltanCampos();
      return;
    }

    this.cargando = true;
    var datosDireccion: any = Object.values(this.direccionesForm.value);

    let alias = datosDireccion[5];
    // let id_state = datosDireccion[1];
    // let id_state = (<HTMLSelectElement>document.getElementsByName('id_state')[0]).selectedOptions[0].innerText;

    // let nombreEstado = datosDireccion[2];
    let postcode = datosDireccion[0];
    let colonyArray = datosDireccion[3];
    let separados = colonyArray.split('-');
    let phone = datosDireccion[8];
    let colony = separados[0];
    // let city = datosDireccion[2];
    var city = (<HTMLSelectElement>document.getElementsByName('city')[0]).selectedOptions[0].innerText;
    let address1 = datosDireccion[6];
    let address2 = datosDireccion[7];


    let id_address = this.id_address;

    let datosDir = {
      "alias": alias,
      "stateValue": this.ciudadsinCodigo,
      "zipcode": postcode,
      "colonyValue": colony,
      "phonenumber": phone,
      "cityValue": city,
      "address1": address1,
      "address2": address2,
      "idDir": id_address,
      "correo": this.usuario.usuario
    }
    this.loginService.actualizaDatosDireccion(datosDir).subscribe(response => {

      this.actualizacionCorrecta();
      // }else if(this.accion = 'agregar'){
      //   this.agregaDireccionCorrecta();
      // }
      // setTimeout(() => {

      this.cargando = false;
      window.location.reload();
      // location.reload();
      // },1000);
    });


  }

  nohaycodigo() {
    Swal.fire({
      icon: 'error',
      title: 'Error!',
      text: 'Código postal no encontrado',
    });

  }


  nuevitaDireccion() {
    if (this.direccionesForm.invalid) {
      this.faltanCampos();
      return;
    }
    var datosDireccion: any = Object.values(this.direccionesForm.value);

    let alias = datosDireccion[5];
    // let id_state = datosDireccion[1];
    // let id_state = (<HTMLSelectElement>document.getElementsByName('id_state')[0]).selectedOptions[0].innerText;

    // let nombreEstado = datosDireccion[2];
    let postcode = datosDireccion[0];
    let colonyArray = datosDireccion[3];
    let separados = colonyArray.split('-');
    let phone = datosDireccion[8];
    let colony = separados[0];
    // let city = datosDireccion[2];
    var city = (<HTMLSelectElement>document.getElementsByName('city')[0]).selectedOptions[0].innerText;
    let address1 = datosDireccion[6];
    let address2 = datosDireccion[7];

    let arregloDir = {
      "alias": alias,
      "phonenumber": phone,
      "zipcode": postcode,
      "address1": address1,
      "address2": address2,
      "stateValue": this.ciudadsinCodigo,
      "cityValue": city,
      "colony": colony,
      "correo": this.usuario.usuario
    }

    this.loginService.enviaDireccion(arregloDir).subscribe((datos: any) => {
      location.reload();
    })


  }

  agregaDireccionCorrecta() {
    Swal.fire({
      icon: 'success',
      title: 'Confirmación',
      text: 'Se ha agregado su direccion correctamente',
    });

  }

  actualizacionCorrecta() {
    Swal.fire({
      icon: 'success',
      title: 'Confirmación',
      text: 'Su direccion ha sido modificada',
    });

  }

  faltanCampos() {
    Swal.fire({
      icon: 'error',
      title: 'Error!',
      text: 'Por favor llene todos los campos o revise la información',
      timer: 3000
    });
  }
}
